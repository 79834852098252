<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :custom-response-data="customResponseData"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'

const semesterOptions = [1, 2, 3, 4, 5, 6, 7, 8]

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_mkcp',
      customResponseData(responseData) {
        const firstMataKuliahPilihans = []
        responseData.forEach((data, index) => {
          const firstMatkulPilihanHasAdded = firstMataKuliahPilihans.some(
            matkulPilihan => matkulPilihan.semester == data.semester && matkulPilihan.name == data.matkul_pilihan.name
          )
          if (!firstMatkulPilihanHasAdded) {
            firstMataKuliahPilihans.push({
              semester: data.semester,
              name: data.matkul_pilihan.name,
              index
            })
          }
        })
          
        let insertedMatkulPilihan = 0
        firstMataKuliahPilihans.forEach(firstMatkulPilihan => {
          const matkulPilihanData = {
            semester: firstMatkulPilihan.semester,
            matkul: {
              code_mk: firstMatkulPilihan.name,
            },
            is_matkul_pilihan: true,
            disableEdit: true
          }

          if (firstMatkulPilihan.index == 0) {
            responseData.unshift(matkulPilihanData)
          } else {
            responseData.splice(firstMatkulPilihan.index + insertedMatkulPilihan, 0, matkulPilihanData)
          }

          insertedMatkulPilihan++
        })
        
        return responseData
      },
      headers: [
        [{
          label: 'Semester',
          dataKey: 'semester',
          order: 1,
          attributes: { rowspan: 2 },
          dataAttributes({ vm, index, data }) {
            const semesterIndexes = semesterOptions.map(semester => {
              const semesterIndex = vm.data.findIndex(data => data.semester == semester)
              const rowspan = vm.data.filter(data => data.semester == semester).length
              return {
                name: semester,
                rowspan,
                index: semesterIndex >= 0 ? semesterIndex : null
              }
            })
            
            const attributes = { class: 'text-center' }
            const currentSemester = semesterIndexes.find(semester => semester.name == data.semester)
            if (currentSemester.index == index) {
              attributes.rowspan = currentSemester.rowspan
            } else {
              attributes.hide = true
            }

            return attributes
          },
        }, {
          label: 'Kode MK',
          dataKey: 'matkul.code_mk',
          order: 2,
          attributes: { rowspan: 2 },
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { colspan: 11, class: 'bg-light' }
            }
            return {}
          },
        }, {
          label: 'Nama Mata Kuliah',
          dataKey: 'matkul.name',
          order: 3,
          attributes: { rowspan: 2 },
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return {}
          }
        }, {
          label: 'Jenis Mata Kuliah',
          attributes: { colspan: 3 },
        }, {
          label: 'Jumlah SKS',
          dataKey: 'jumlah_sks',
          order: 8,
          attributes: { rowspan: 2 },
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          }
        }, {
          label: 'Unit Penyelenggara*',
          attributes: { colspan: 3 },
        }, {
          label: 'Kesesuaian dengan CPL',
          dataKey: 'kesesuaian_dengan_cpl',
          order: 12,
          attributes: { rowspan: 2 },
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Perangkat Pembelajaran',
          dataKey: 'perangkat_pembelajaran',
          order: 13,
          attributes: { rowspan: 2 },
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }],
        [{
          label: 'Teori',
          dataKey: 'teori',
          order: 5,
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Praktikum',
          dataKey: 'praktikum',
          order: 6,
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Praktik',
          dataKey: 'praktik',
          order: 7,
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'PT',
          dataKey: 'pt',
          order: 9,
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'UPPS',
          dataKey: 'upps',
          order: 10,
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'PS',
          dataKey: 'ps',
          order: 11,
          dataAttributes({ data }) {
            if (data.is_matkul_pilihan) {
              return { hide: true }
            }
            return { class: 'text-center' }
          },
          render(data) {
            return data ? '✓' : ''
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Semester',
          name: 'semester',
          dataKey: 'semester',
          type: 'select',
          options: semesterOptions,
        }, {
          label: 'Nama Mata Kuliah',
          name: 'matkul_name',
          dataKey: 'matkul.name',
          type: 'select',
          options: [],
          placeholder: 'Cari mata kuliah',
          
          col: 6,
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('matkul', { params: { full_name: search } })
              let fetchedOptions = response.data.matkuls.map(matkul => ({ label: matkul.name, value: matkul.code_mk }))
              loading(false)
              return [search, ...fetchedOptions]
            }
          },
          customSelectedValue(data, formData) {
            formData.create.code_mk = data.value
            formData.edit.code_mk = data.value
            if (typeof data == 'object') {
              return data.label
            }
            return data
          }
        }, {
          label: 'Kode MK',
          name: 'matkul.code_mk',
          dataKey: 'matkul.code_mk',
          type: 'text',
          
          col: 6,
        }, {
          label: 'Mata Kuliah Pilihan',
          name: 'matkul_pilihan_name',
          dataKey: 'matkul_pilihan.name',
          type: 'select',
          options: [],
          placeholder: 'Cari mata kuliah pilihan',
          
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('matkul_pilihan', { params: { full_name: search } })
              let fetchedOptions = response.data.matkul_pilihans.map(matkul => matkul.name)
              loading(false)
              return [search, ...fetchedOptions]
            }
          },
        }, {
          label: 'Teori',
          name: 'teori',
          dataKey(data) {
            return data.teori ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4,
        }, {
          label: 'Praktikum',
          name: 'praktikum',
          dataKey(data) {
            return data.praktikum ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4,
        }, {
          label: 'Praktik',
          name: 'praktik',
          dataKey(data) {
            return data.praktik ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4,
        }, {
          label: 'PT',
          name: 'pt',
          dataKey(data) {
            return data.pt ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4,
        }, {
          label: 'UPPS',
          name: 'upps',
          dataKey(data) {
            return data.upps ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4,
        }, {
          label: 'PS',
          name: 'ps',
          dataKey(data) {
            return data.ps ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
          col: 4,
        }, {
          label: 'Jumlah SKS',
          name: 'jumlah_sks',
          dataKey: 'jumlah_sks',
          type: 'text',
          defaultValue: 0,
        }, {
          label: 'Kesesuaian dengan CPL',
          name: 'kesesuaian_dengan_cpl',
          dataKey(data) {
            return data.kesesuaian_dengan_cpl ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
        }, {
          label: 'Perangkat Pembelajaran',
          name: 'perangkat_pembelajaran',
          dataKey(data) {
            return data.perangkat_pembelajaran ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
        }],
        customPayload(data) {
          return {
            ...data,
            teori: data.teori ? 1 : 0,
            praktikum: data.praktikum ? 1 : 0,
            praktik: data.praktik ? 1 : 0,
            pt: data.pt ? 1 : 0,
            upps: data.upps ? 1 : 0,
            ps: data.ps ? 1 : 0,
            kesesuaian_dengan_cpl: data.kesesuaian_dengan_cpl ? 1 : 0,
            perangkat_pembelajaran: data.perangkat_pembelajaran ? 1 : 0,
          }
        }
      },
    }
  }
}
</script>